import Header from '../header/header'
import Footer from '../footer/footer'
import { Outlet } from 'react-router-dom'

const Container = ({ children }) => {
  return (
    <div className='d-flex flex-column' style={{minHeight: '100vh'}}>
      <Header />
      <header className="slide-down text-white" style={{minHeight:"10rem"}}>
      </header>
      <div className='flex-grow-1'>
        <div className="container bg-white py-4 px-lg-5">
          <Outlet />
        </div>
      </div>
      
      <Footer />
    </div>
  )
}

export default Container
