import { useHistory ,useLocation } from 'react-router-dom';

const Page = () => {
    const location = useLocation()

	return (
		<>
			<h1 className="display-3 mb-4">Page</h1>
			{location.pathname}
		</>
	)
}
export default Page