import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <footer className="py-3 py-lg-5 px-lg-5">
            <div className="container">
                <div className="d-flex flex-wrap align-items-start justify-content-between">
                    <div>
                        <div className="my-3">© Copyright 2020 | Xeedo Technologies</div>
                        4th Floor, 43, SGR Plaza, Above Federal Bank, Marathahalli, Bangalore, Karnataka-560037, India
                    </div>
                    <div>
                        <ul className="list-unstyled p-0 m-0">
                            <li><Link to="privacy">Privacy polocies</Link></li>
                            <li><Link to="terms">Terms of use</Link></li>
                            <li><Link to="career">Career</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default Footer
