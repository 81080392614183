import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

const Nav = () => {
	const [lang, setLang] = useState('en')
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<div className='sticky-top bg-white shadow' >
			<div className="container">
				<Helmet htmlAttributes={{ lang: lang }} />
				<nav className="navbar navbar-expand-lg bg-white navbar-light">
					<div className="container-fluid">
						<Link className="navbar-brand" to="/">
							<img src='img/xeedo.jpg' className='img-fluid' style={{ maxHeight: '3rem' }} />
						</Link>
						<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText"
							aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
						</button>
						<div className="collapse navbar-collapse" id="navbarText">
							<ul className="navbar-nav me-auto mb-2 mb-lg-0">
							<li class="nav-item dropdown">
									<a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
										About
									</a>
									<ul class="dropdown-menu">
										{/* <li><Link className="dropdown-item" to="about">About</Link></li> */}
										<li><Link className="dropdown-item" to="why-xeedo">Why Xeedo</Link></li>
										<li><Link className="dropdown-item" to="management-team">Management Team</Link></li>
										<li><Link className="dropdown-item" to="vertical-model">Vertical/Model</Link></li>
										<li><Link className="dropdown-item" to="service-offering">Services Offering</Link></li>
									</ul>
								</li>
								<li class="nav-item dropdown">
									<a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
										Services
									</a>
									<ul class="dropdown-menu">
										{/* <li><Link className="dropdown-item" to="services">Services</Link></li> */}
										<li><Link className="dropdown-item" to="embedded">Embedded</Link></li>
										<li><Link className="dropdown-item" to="software">Software</Link></li>
										<li><Link className="dropdown-item" to="vlsi">VLSI</Link></li>
									</ul>
								</li>

								{/* <li className="nav-item">
									<Link className="nav-link" to="services">Services</Link>
								</li> */}
								<li className="nav-item">
									<Link className="nav-link" to="products">Products</Link>
									<ul class="dropdown-menu">
										<li><Link className="dropdown-item" to="iot-products">IoT Products</Link></li>
										<li><Link className="dropdown-item" to="software">Software</Link></li>
										<li><Link className="dropdown-item" to="vlsi">VLSI</Link></li>
									</ul>
								</li>
								<li className="nav-item">
									<Link className="nav-link" to="career">Career</Link>
								</li>
								<li className="nav-item">
									<Link className="nav-link" to="contact">Contact</Link>
								</li>
							</ul>
							<span className="navbar-text d-flex">
								<div className="dropdown">
									<button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
										Log in
									</button>
									<ul className="dropdown-menu" style={{ right: 0, left: 'auto' }}>
										<li><a target='_blank' href='https://xeedo.in:2096/' className="dropdown-item">Webmail</a></li>
										<li><a target='_blank' href="http://106.51.131.73:85/xeedoess" className="dropdown-item" type="button">Saral</a></li>
									</ul>
								</div>
							</span>
						</div>
					</div>
				</nav>
			</div>
		</div>
	)
}

export default Nav
