
const Embedded = () => {

	return (
		<>
			<h1 className="display-3 mb-4">Embedded</h1>
            <div className="cards p-0">
                <div className="card">
                    <div className="card-body">
                        <h5>Reference Platform</h5>
                        <p>
                            Porting, Optimization, Enhancement of OS to various hardware platforms Integration of Media Frameworks and Open GL. Development and customization as per customer requirements. System integration and release management.
                        </p>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <h5>Device Drivers</h5>
                        <p>
                            BSP and Device driver development is backbone of embedded systems. We have expertise in BSP, Device Drives based on various OS, Kernel, and Boot Loaders, Sensor integration, Memory, power and Performance.
                        </p>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <h5>Middleware</h5>
                        <p className="text-more" style={{maxHeight:'8.25rem'}}>
                            Middleware is a software that mediates between application software and the kernel or device driver software. Middleware is an abstraction layer generally used on embedded devices with two or more applications in order to provide flexibility, security, portability, and connectivity, intercommunication, and/or interoperability mechanisms between applications. Middleware allows reducing complexity of the applications by centralizing software infrastructure that would traditionally be redundantly found in the application layer. However, in introducing middleware to a system, one introduces additional overhead, which can greatly affect scalability and performance. In short, middleware affects the embedded system at all layers.
                        </p>
                        <div className="text-center">
                            <button className="btn btn-link btn-text-more">Read more...</button>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                        <h5>MM, Stack</h5>
                        <p style={{minHeight:'9.5rem'}}>
                            We have expertise in areas of Image processing, Audio, Video, Camera, Display & Graphics, IVI, pre and post processing algorithms. 3rd party integration and code optimization.
                        </p>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <h5>Connectivity</h5>
                        <p>
                            Blue Tooth, Wi-Fi, NFC, RFIDGPRS, 3G, HSPA, LTE, VoLTE, 5G, TCP/IP, UDP,Routing, Switching, L2 Protocols.
                        </p>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <h5>Automation</h5>
                        <p>
                            Build, Integration, Release, Automation scripting, testing, Test frameworks.DevOps, Git, Gerrit, Jenkin, Python.
                        </p>
                    </div>
                </div>
            </div>
		</>
	)
}
export default Embedded