
const ManagementTeam = () => {

	return (
		<>
			<h1 className="display-3 mb-4">Management Team</h1>
			<div className="d-flex shadow">
				<img src="https://xeedo.in/wp-content/uploads/2022/02/Chatur_passport.jpg" className="img-fluid img-thumbnail" style={{maxHeight:'20rem'}} />
				<div className="ps-4">
					<h2>Mr. Chatur Gadhia</h2>
					<h5 className="text-muted">VP-TECHNOLOGY</h5>
					<p>
						Chatur Gadhia is Vice President – Technology at Xeedo Technologies. An Electronics Engineer having Post Graduate Degree (Marketing) comes with 30+ Years of experience in IT industry
					</p>
					<p>In this role, he oversees Xeedo’s Software, embedded Software Technology portfolio services.</p>
					<p>Prior to this role, Chatur managed software solutions, products and services in telecom, appliances and industrial domains at LG Electronics, Toshiba, Tata Telecom, GCEL etc.</p>
					<p>He played critical role by leading development teams developing Reusable Middleware Software components, Device Drivers, OSless OS and Multimedia, Framework, Baseband for 3G, 2.5G and WLAN using ARM / FPGA / CPLD / ADC / DAC, SW for EPABX, DMARR, 2Ghz radio, DMARR, 2x15 systems and NMS</p>
					<p>He managed Global Outsourcing by setting up cost effective ODCs, involved in managing corporate planning by setting up TRM and PRM, Project proposals, Budget, Costing, Billing, Projects status, Bench Marking Reports, Competence Mapping, Skill development etc.</p>
				</div>
			</div>
			<div className="mt-5 d-flex shadow">
				<img src="https://xeedo.in/wp-content/uploads/2022/07/DSC_2422a-scaled-e1657256707582.jpg" className="img-fluid img-thumbnail" style={{maxHeight:'20rem'}} />
				<div className="ps-4">
					<h2>Mr. Ansuman Padhy</h2>
					<h5 className="text-muted">CHIEF OPERATION OFFICER</h5>
					<p>
						Ansuman Padhy is Chief Operation Officer of Xeedo Technologies.
					</p>
					<p>
						An Electrical & Electronics Engineer having post graduate degree in HR and Marketing comes with 10+ Years of prior experience in diversified fields in IT industry.
					</p>
					<p>
						In this role, he oversees all the operations of Xeedo in various departments.
					</p>
					<p>
						Prior to this role, he had worked for various companies getting exposure to various fields like HR, Recruitment, Accounts, Business Development, etc.
					</p>
				</div>
			</div>
		</>
	)
}
export default ManagementTeam