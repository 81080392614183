const Contact = () => {
  return (
    <>
      <h1 className="display-3 mb-4">Contact</h1>
      <div className="mt-5">
        <h4 className="my-4">Xeedo Technologies Private Limited</h4>
        Phone: <a className="text-black" href="tel:+91 8095660555">+91 8095660555</a>
        <div className="my-2">
          Email: <a className="text-black" href="mailto:info@xeedo.in">info@xeedo.in</a>
        </div>
        <h4 className="mt-4">Head Office (Bangalore)</h4>
        Xeedo Technologies Pvt Ltd. #43, A-3, 4th Floor, SGR Plaza, Above Federal Bank, Marathahalli, Bangalore, Karnataka-560037, India
        <div className="my-3">
          <iframe src="https://maps.google.com/maps?q=xeedo%20tecnologies%20bangalore&t=m&z=15&output=embed&iwloc=near" height="400px" width="800px"></iframe>
        </div>
        <div className="my-5">
        <h4>Branch Office (Hyderabad)</h4>
          Xeedo Technologies Pvt. Ltd. 4th Floor, Kapil Towers, Financial District, Gachibowli, Hyderabad, Telangana, 500032 India
          <div className="my-3">
            <iframe src="https://maps.google.com/maps?q=xeedo%20technologies%20hydrabad&t=m&z=14&output=embed&iwloc=near" height="400px" width="800px"></iframe>
          </div>
        </div>
      </div>
    </>
  )
}
export default Contact