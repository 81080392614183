
const Software = () => {
    return (
        <>
            <h1 className="display-3 mb-4">Software</h1>
            <div>
                <h3>Cloud Services</h3>
                <p>
                    Cloud computing technologies facilitate responsiveness, scalability, innovation, and agility enabling enterprises and software product vendors to optimize cost and drive transformation. We work closely with our customers to understand their business processes and objectives and help them by providing right services on time. We have expertise and experience in various cloud platforms including Windows Azure, Amazon Web Service (AWS), Rackspace and Google Cloud Platform (GCP)
                </p>
                <h3>Big Data</h3>
                <p>
                    The ultimate goal for most of the companies deploying Big Data technology is to sift through large volumes of data and utilize analytics & insights to make better business decisions and identify actionable way forward. We have expertise and experience working with various data visualization and analytics tools like QlikView, QlikSense, Tableau, SAS, R, IBM Cognos, Azure ML and many more, which sets us apart from other Big Data Service Providers. Our capabilities in Big Data Analytics Solutions space includes.
                    <ul>
                        <li>Inferential Statistical Analysis</li>
                        <li>Machine Learning/Data Mining</li>
                        <li>Data Modelling</li>
                        <li>Predictive and Prescriptive Analytics</li>
                        <li>Analytics Optimization</li>
                        <li>Data Visualization Solutions</li>
                    </ul>
                </p>

                <h3>Mobility</h3>
                <p>
                    Mobile technology is continuously evolving and reshaping today’s business and technology landscape. As the proliferation of mobile devices continues,
                    enterprises find it extremely challenging to manage and maintain multiple applications across various platforms and keep them secure.<br />
                    <b>Application development:</b>
                    Native applications, Hybrid and mobile web applications, Customer-centric mobile UI/UX, Application upgrade and migration.<br />
                    <b>Mobile Device Management:</b>
                    Enterprise mobile security strategy, Configuration and deployment, Multi-OS device management, Security and risk management<br />
                    <b>Mobile Testing:</b>
                    Functional testing, Mobile performance testing, Mobile automation testing, Usability testing, Security testing.<br />
                    <b>Platform Development:</b>
                    Porting, Optimization, Enhancement of OS to various hardware platforms. Integration of Media Frameworks and Open GL.
                    Development and customization as per customer requirements. System integration and release management
                </p>
            </div>
        </>
    )
}
export default Software