import Header from '../header/header'
import Footer from '../footer/footer'
import { Outlet } from 'react-router-dom'

const Layout = ({ children }) => {
  return (
    <div className='d-flex flex-column' style={{minHeight:'100vh'}}>
      <Header />
      <div className='flex-grow-1'>
        <Outlet />
      </div>
      <Footer />
    </div>
  )
}

export default Layout
