
const WhyXeedo = () => {
	return (
		<>
			<h1 className="display-3 mb-4">Why Xeedo?</h1>
			<p>
				Xeedo Technologies provides comprehensive solutions and services that meet and exceed the business needs and goals of our clients. Our company prides itself on the consultative partnerships and solutions we provide for every employment demand. Beyond this, Xeedo Technologies as a creative force with a focus on building an environment that fosters trust, honesty, interconnectivity and accountability between our teams and client organizations.
			</p>
			<p>
				Our team has the vast experience on Technical as well as Managerial in Semiconductor and Embedded System services worked with various multinationals in worldwide locations.
			</p>
			<ul className='p-3 m-0'>
				<li>
					Xeedo Technologies creates products and services with high customer value.
				</li>
				<li>
					This results in high customer and service orientation.
				</li>
			</ul>
		</>
	)
}
export default WhyXeedo