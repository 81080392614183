import { Link } from "react-router-dom";

const Home = () => {

	return (
		<>
			<header className="slide-down text-white d-flex flex-column justify-content-center">
				<div className='container'>
					<div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
						<div className="carousel-indicators">
							<button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
							<button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
							<button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
						</div>
						<div className="carousel-inner">
							<div className="carousel-item text-center active">
								<img src="img/banner1.png" className="img-fluid" />
							</div>
							<div className="carousel-item text-center">
								<img src="img/banner2.png" className="img-fluid" />
							</div>
							<div className="carousel-item text-center">
								<img src="img/banner3.png" className="img-fluid" />
							</div>
						</div>
					</div>
				</div>
			</header>

			<div className="container" style={{ minHeight: '10rem' }}>
				<section className="cards my-5">
					<div className="d-flex">
						<div className="card">
							<div className="card-body">
								<div>
									<a href="#" className="h4">Consulting</a>
									<h5 className="mt-3">HR</h5>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
								</div>
							</div>
						</div>
					</div>
					<div className="d-flex">
						<div className="card">
							<div className="card-body">
								<div>
									<a href="#" className="h4">Technologies</a>
									<h5 className="mt-3">Semiconductor Design</h5>
									Our vast experience in design, verification, design For testabilty, Physical design along with silicon validation and verification make eligible to support our customers for end to end development and service.
								</div>
							</div>
						</div>
					</div>
					<div className="d-flex">
						<div className="card">
							<div className="card-body">
								<div>
									<a href="#" className="h4">Staffing</a>
									<h5 className="mt-3">Recruitment</h5>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	)
}
export default Home