import { Route, Routes } from 'react-router-dom'
import Layout from './components/shared/layout/layout'
import Container from './components/shared/layout/container'
import Home from './components/home/home'
import About from './components/pages/about'
import Contact from './components/pages/contact'
import Page from './components/pages/page'
import Services from './components/pages/services'
import Products from './components/pages/products'
import WhyXeedo from './components/pages/why-xeedo'
import ManagementTeam from './components/pages/management-team'
import Embedded from './components/pages/embedded'
import Software from './components/pages/software'
import Privacy from './components/pages/privacy'
import Terms from './components/pages/terms'
import Career from './components/pages/career'
import IoTProducts from './components/pages/iot-products'


function App() {
  return (
    <Routes>
      <Route element={<Container />}>
        
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/products" element={<Products />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/career" element={<Career />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />

        <Route path="/why-xeedo" element={<WhyXeedo />} />
        <Route path="/management-team" element={<ManagementTeam />} />
        <Route path="/vertical-model" element={<Page />} />
        <Route path="/service-offering" element={<Services />} />

        <Route path="/vlsi" element={<Page />} />
        <Route path="/software" element={<Software />} />
        <Route path="/embedded" element={<Embedded />} />

        <Route path="/iot-products" element={<IoTProducts />} />
        <Route path="/semiconductor-design" element={<Page />} />
        <Route path="/embedded-software" element={<Page />} />
        <Route path="/software-enginnering" element={<Page />} />
        
        <Route path="/openings" element={<Page />} />
        <Route path="/client-base" element={<Page />} />
        <Route path="/partners" element={<Page />} />
        <Route path="/life-at-xeedo" element={<Page />} />
        
        

        <Route path="*" component={<Home />} status={404} />
      </Route>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
      </Route>
    </Routes>
  )
}

export default App
